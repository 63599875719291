<template>
  <div>
    <div class="bordered__container_mobile">
      <div class="d-flex justify-content-between align-items-center" v-b-toggle.collapse-compare>
        <h4 class="float-left blog__text text-dark" style="text-transform: none;">
          {{ selectedCars.length }} auto's vergelijken
        </h4>
        <i class="mouseover mdi mdi-chevron-down mdi-24px"/>
      </div>


      <b-collapse :visible="openCollapse"  id="collapse-compare">
        <div class="content mt-3" style="background-color: white; padding: 20px 20px 0px;">

          <div v-for="car in selectedCars">
            <div class="row">
              <div class=" mouseover col-4 d-flex justify-content-center align-items-center">

                <img class="car car-image rounded"
                     :src="'https://media.autodisk.nl/fotos/geel/'+car.nModelID+'/16-9/300/a.jpg'"
                     :alt="car.cMerknaam+' '+car.cModelnaam" itemprop="image"
                     :key="'car-image-' + car.nModelID" loading="lazy"
                     onerror="this.onerror=null;this.srcset='/banner.png';"
                >
              </div>
              <div class="col-6 flex-wrap justify-content-center align-items-center">
                        <span class="mb-0 text-colored font-weight-bold"
                              style="font-size: 18px ; white-space: nowrap;  overflow: hidden; text-overflow: ellipsis;">{{
                            car.cMerknaam
                          }} {{ car.cModelnaam }}</span>
                <span v-if="car.cTypenaam_1 && car.Vermogen_elektromotor_kW" class="text-colored font-weight-bold">{{car.cTypenaam_1}} ({{ Math.round(parseInt(car.Vermogen_elektromotor_kW) * 1.36) }}pk)</span>
              </div>
              <div class="col-2 d-flex justify-content-center align-items-center">
                <div @click="deleteCar(car)" class="close-button cursor-pointer">
                  <i class="mdi mdi-close"></i>
                </div>
              </div>
            </div>

            <hr/>
          </div>

        </div>
        <div class="d-flex justify-content-center" style="background-color: #effbf4"> <!-- Add this container -->
          <button class="compare_button " :disabled="selectedCars.length === 1 " @click="reportEvent('compare-cars', selectedCars)">
            Vergelijken

          </button>
        </div>
      </b-collapse>
    </div>

    <div class="bordered__container shadow">
      <div class="d-flex justify-content-between align-items-center" v-b-toggle.collapse-compare>
        <h4 class="float-left blog__text text-dark" style="text-transform: none;">
          {{ selectedCars.length }} auto's vergelijken
        </h4>
        <i class="mouseover mdi mdi-chevron-down mdi-24px"/>
      </div>
      <b-collapse :visible="openCollapse" id="collapse-compare">
        <div class="content rounded mt-3" style="background-color: white; padding: 20px 20px 20px;">


          <div class="row">
            <div class="col-md-9">
              <div class="row">
                <div class="col-md-4 " v-for="car in selectedCars.slice(0, 3)">
                  <div @click="deleteCar(car)" class="mouseover d-flex flex-column align-items-center "
                       style="box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);">
                    <span v-if="car.cTypenaam_1 && car.Vermogen_elektromotor_kW" style="top: 0; left: 15px; font-size: 10px; padding: 3px" class="vergelijk_knop position-absolute">{{car.cTypenaam_1}} ({{ Math.round(parseInt(car.Vermogen_elektromotor_kW) * 1.36) }}pk)</span>
                    <img class="car car-image rounded"
                         :src="'https://media.autodisk.nl/fotos/geel/'+car.nModelID+'/16-9/300/a.jpg'"
                         :alt="car.cMerknaam+' '+car.cModelnaam" itemprop="image"
                         :key="'car-image-' + car.nModelID" loading="lazy"
                         onerror="this.onerror=null;this.srcset='/banner.png';"
                    >
                    <span class="mt-1 pb-1 text-colored font-weight-bold"
                          style="font-size: 16px; max-width: 150px;  white-space: nowrap !important; overflow: hidden!important; text-overflow: ellipsis!important;">{{
                        car.cMerknaam
                      }} {{ car.cModelnaam }}</span>
<!--                    <span v-if="car.cTypenaam_1 && car.Vermogen_elektromotor_kW" class="text-colored font-weight-bold  pb-1"  style="font-size: 14px;">({{car.cTypenaam_1}} met {{ Math.round(parseInt(car.Vermogen_elektromotor_kW) * 1.36) }}pk)</span>-->
                    <div class="close-button cursor-pointer" style="top: 0; right: 15px;">
                      <i class="mdi mdi-close"></i>
                    </div>
                  </div>
                </div>
                <div class="col-md-4" v-for="n in (3 - selectedCars.length)" v-if="selectedCars.length < 3">
                  <div class="dashed-box">
                    <div class="plus"></div>
                  </div>
                </div>

              </div>

            </div>

            <div class="col-md-3">
              <div class="d-flex justify-content-center align-items-center" style=" height: 100%;">
                <!-- Add this container -->
                <button class="compare_button " :disabled="selectedCars.length === 1 " @click="reportEvent('compare-cars', selectedCars)">
               Vergelijken

                </button>

              </div>
            </div>
          </div>


        </div>
      </b-collapse>
    </div>
  </div>


</template>

<script>
export default {
  props: {
    selectedCars: [],
    openCollapse: null,
  },
  data() {
    return {
      post: undefined,
      dontShow: false,
      test: undefined,
    }
  },


  computed: {
    models() {
      return this.$store.state.models
    },
  },

  methods: {
    reportEvent(event, car) {

      const seoModel = car.map(car => `${car.cMerknaam} ${car.cModelnaam}`).join(', ');
      console.log(seoModel);


      const id = car.nModelID;
      this.$gtag('event', `clicked-on-${event}`, {
        event_category: 'Compare cars',
        event_label: seoModel,
        value: id,
      });

      this.$router.push({ name: 'vergelijker' })

    },

    // setComparedCars() {
    //   this.$store.commit('setComparedCars', this.selectedCars)
    //   console.log('vet', this.selectedCars)
    //   this.$router.push('vergelijk/vergelijker')
    // },

    deleteCar(car) {
      const index = this.selectedCars.indexOf(car);
      if (index !== -1) {
        this.selectedCars.splice(index, 1);
        sessionStorage.setItem('selectedCars', JSON.stringify(this.selectedCars));
        this.$emit('update')
      }
    },


    // removeTags(str) {
    //   if ((str===null) || (str===''))
    //     return false;
    //   else
    //     str = str.toString();
    //
    //   // Regular expression to identify HTML tags in
    //   // the input string. Replacing the identified
    //   // HTML tag with a null string.
    //   return str.replace( /(<([^>]+)>)/ig, '');
    // },
    // reportEvent(event, post){
    //   this.$gtag('event', `clicked-on-${event}`, {
    //     event_category: 'Model Overview',
    //     event_label: post.post_name,
    //   });
    // },
    closeBlogPost() {
      this.dontShow = true;
    }
  }
}
</script>
<style lang="scss" scoped>

.compare_button {
  padding: 10px 20px;
  background: #2ecc71;
  color: white;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  border: 2px solid #2ecc71;
  display: inline-block;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background 0.3s ease, border 0.3s ease, color 0.3s ease; /* Added transition */


}
.compare_button:disabled {
  border: none;
  background: rgba(169, 169, 169, 0.44);
  color: #ccc; /* Optional: change text color for disabled state */
}

a.compare_button {
  color: #fff !important;
}

.row-divider {
  border-top: 1px solid lightgrey;
  margin-top: 10px;
  padding-top: 10px;
}

.badge-pill {
  width: 100%;

  &.badge-vergelijking {
    display: flex;
    justify-content: space-between;
    margin: 1px 3px;
    padding: 0;
    flex-direction: row;
    align-items: center;

    .badge__text {
      padding: 0 6px 0 12px;
    }

    .mdi {
      display: block;
      padding: 8px;
      margin: 3px;
      width: auto;
      background-color: #000;
      border-radius: 100%;
    }

    &.badge-vergelijking--success {
      background-color: lighten(#2ecc71, 5%);
      color: white;

      .mdi {
        font-size: 16px;
        color: white;
        background-color: darken(#2ecc71, 5%);
      }
    }

    &.badge-vergelijking--danger {
      background-color: lighten(#e74c3c, 10%);
      color: white;

      .mdi {
        color: white;
        background-color: #e74c3c;
      }
    }
  }


}

.close-button {
  position: absolute;
  background-color: #ff0000;
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;


  i {
    font-weight: bold;
    font-size: 11px;
    color: white;
  }
}

.blog__text {
  font-weight: bold;
  margin: 0px;
  color: #666;
}

.blog__content {
  margin-bottom: 0;
}

.bordered {
  // position: fixed;
  // z-index: 1000;
  // bottom: 20px;
  // right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  padding: 12px;
  background-color: lighten(#2ecc71, 45%);
}

.bordered__container {
  display: block;

  h4 {
    width: 90%;
  }

  //max-width: 800px;
  padding: 20px;
  width: auto;
  border-radius: 8px 28px 8px 8px;
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-color: #e6f9ee !important;

}


.dashed-box {
  width: 150px;
  height: 100px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: linear-gradient(to right, #595959 50%, rgba(255, 255, 255, 0) 0%),
  linear-gradient(to right, #595959 50%, rgba(255, 255, 255, 0) 0%),
  linear-gradient(to bottom, #595959 50%, rgba(255, 255, 255, 0) 0%),
  linear-gradient(to bottom, #595959 50%, rgba(255, 255, 255, 0) 0%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-position: top, bottom, left, right;
  background-size: 3px 1px, 3px 1px, 1px 3px, 1px 3px;
  box-sizing: border-box;
  padding: 1px; /* This padding is to ensure the gradients are visible */
}

.plus {
  position: relative;
  width: 20px;
  height: 20px;
}

.plus::before, .plus::after {
  content: '';
  position: absolute;
  background-color: #595959;
}

.plus::before {
  width: 100%;
  height: 10%;
  top: 45%;
  left: 0;
}

.plus::after {
  width: 10%;
  height: 100%;
  top: 0;
  left: 45%;
}

.bordered__container_mobile {
  display: none;

  h4 {
    width: 90%;
  }

  //max-width: 800px;
  padding: 20px;
  width: auto;
  border-radius: 8px 28px 8px 8px;
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-color: #e6f9ee !important;

}


/* Styles for screens wider than 425px - hide mobile container, show desktop container */
@media (min-width: 426px) {
  .bordered__container_mobile {
    display: none;
  }
  .bordered__container {
    display: block;
  }
}

/* Styles for screens 425px wide or less - show mobile container, hide desktop container */
@media (max-width: 440px) {
  .bordered__container_mobile {
    display: block;
  }
  .bordered__container {
    display: none;
  }
}

@media (min-width: 1025px) {
  .bordered__container {
    padding: 20px;
    width: 800px;
    border-radius: 8px 28px 8px 8px;
    background-size: 50% 50%;
    background-repeat: no-repeat;
  }
}

</style>

<template lang="html" xmlns="http://www.w3.org/1999/html">
  <div class="elektrisch-overview" v-if="models != null">

    <!-- BANNER -->
    <div class="banner">

      <div class="top-header" style="background:#f0f8ff;background:#2ecc7114 !important;">
        <i class="mdi mdi-car-hatchback driving-car"></i>
        <i class="mdi mdi-tree front-tree"></i>
        <i class="mdi mdi-tree back-tree"></i>

        <Shape class="position-absolute shape bottom-1"/>
      </div>
    </div>
    <!--END  BANNER -->

    <div class="features">
      <div class="container">
        <div class="row">
          <div class="col-md-6">

            <div class="loader" v-if="models.length == 0"></div>
            <h4 v-else><span v-scroll-to="'.model-list'"
                             class="mouseover">Bekijk de {{ models.length }} EV Auto's</span></h4>
            <h1>Elektrische auto's<br> op de Nederlandse markt</h1>
            <p>
              WhatTheRange heeft deze site gebouwd om een goed beeld te krijgen van alle actuele en te verwachten <b>elektrische</b>
              auto's op de Nederlandse markt.
              De opgaven van het verbruik en de actieradius van je elektrische auto worden opgegeven door de fabrikanten
              in <b>NEDC</b> of <b>WLTP</b> waarden. WLTP waarden kloppen in praktijk beter dan NEDC waarden, maar ook
              die waarden zijn te voordelig ingeschat.
              Om die reden hebben we uitgerekend en soms ook getest wat de <b>verwachte</b> actieradius en verbruik is.
              Zoals je zult begrijpen kunnen hieraan geen rechten worden ontleend. We proberen je vooral een reëler
              beeld te geven van de werkelijkheid.
            </p>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <div class="searchbox searchbox__sticky" id="searchbox">
                  <div class="input-group stylish-input-group">
                    <input type="text" placeholder="Zoeken" class="form-control input-search" name="zoeken"
                           v-model="searchQuery">
                    <span class="input-group-addon">
                      <div class="cursor-pointer" name="zoeken" @click="searchQuery = ''">
                          <i class="mdi mdi-magnify" v-if="searchQuery == ''"></i>
                          <i class="mdi mdi-close" v-else></i>
                      </div>
                    </span>
                  </div>
                </div>


                <div class="">
                  <v-select
                    class="style-chooser mt-2 w-100"
                    v-model="selectedMerk"
                    placeholder="Kies een merk"
                    :options="merken"
                    style="max-width: 100%;"
                  />
                </div>

                <div class="range">
                  <ul class="fast-menu fast-filter" v-if="!this.selectedModel">
                    <li v-if="selectedFilter != null" v-for="(filter, index) in $store.state.filterOptions" :key="index"
                        :value="filter.friendly" :class="selectedFilter.friendly == filter.friendly ? '' : 'open'"
                        @click.prevent="updateFilterUrl(filter)">{{ filter.name }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- -->
    <div class="model-list">
      <div class="container">
        <div class="row p30 pt34 max-w-10">
          <div class="col-md-6 col-xl-4 col-block text-center p-relative car-block"
               v-for="(model, index) in orderBy(filterList, selectedFilter.webserviceField, selectedFilter.webserviceSort)"
               :key="'car-block-' + model.nModelID">
            <div class="ad-logo">
              <div class="outer-spin">
                <div class="outer-arc outer-arc_start-a"></div>
                <div class="outer-arc outer-arc_end-a"></div>
                <div class="outer-arc outer-arc_start-b"></div>
                <div class="outer-arc outer-arc_end-b"></div>
                <div class="outer-moon-a"></div>
                <div class="outer-moon-b"></div>
              </div>
            </div>
            <div class="vergelijk future" itemscope itemtype="https://schema.org/Car">
              <div class="vergelijk_knop " v-if="selectedCars.length < 3">
                <label class="custom-checkbox mb-0 font-weight-bold" >
                  <input :value="model" v-model="selectedCars" @change="handleCheckboxChange" type="checkbox">
                  <span class="checkmark "></span>
                  Vergelijk
                </label>
              </div>
              <div class="badge-verwacht" v-if="!model.bActueel">
                <span>Verwacht</span>
              </div>
              <span itemprop="sku" class="d-none">{{ model.nModelID }}</span>
              <div class="pattern bg-white position-relative">

                <img class="car car-image" :src="'https://media.autodisk.nl/fotos/geel/'+model.nModelID+'/16-9/300/a.jpg'"
                     :alt="model.cMerknaam+' '+model.cModelnaam" itemprop="image"
                     :key="'car-image-' + model.nModelID" loading="lazy"
                      onerror="this.onerror=null;this.srcset='/banner.png';"
                     width="300" height="169"
                     :srcset="`${'https://media.autodisk.nl/fotos/geel/'+model.nModelID+'/16-9/600/a.jpg'} 1920w, ${'https://media.autodisk.nl/fotos/geel/'+model.nModelID+'/16-9/300/a.jpg'} 350w`"
                     >

                <Shape class="position-absolute shape"/>
              </div>
              <h2 class="text-colored mt-15 f22" itemprop="name">
                <span itemprop="brand">{{ model.cMerknaam }}</span>
                {{ model.cModelnaam }}
              </h2>
              <p class="text-center p0 p-0">
                <span v-for="(motor,index) in model.motor">

                  <nuxt-link :to="getRoute(model, 'elektrische-auto')+getTypeRoute(motor)"
                             @click.native="reportEvent('specificaties type', model)"
                             rel="noopener">{{motor.cTypenaam_1}} ({{Math.round(parseInt(motor.typeinformation[0].Vermogen_elektromotor_kW) * 1.36)
                    }}pk)</nuxt-link><span
                  v-if="(index+1) == model.motor.length"></span> <span v-else>, </span>
                </span>
              </p>
              <p itemprop="description">
                De {{ model.cMerknaam + ' ' + model.cModelnaam }} is een elektrische auto uit het
                {{ getSegment(model.motor[0].typeinformation[0].cSegmentAdvanced) }} segment
              </p>

              <div itemprop="offers" itemscope itemtype="http://schema.org/Offer">

                <p class="p-0 price" v-if="model.nLPrijs != null">
                  <b><span itemprop="priceCurrency" content="EUR">&euro;</span> <span
                    itemprop="price">{{ carPrice(model.nLPrijs) }}</span>,-</b>
                </p>
                <p class="p-0 price" v-else>
                  <b><span itemprop="priceCurrency" content="EUR">&euro;</span> <span
                    itemprop="price">n.n.b</span>,-</b>
                </p>

                <div class="row">
                  <div class="col-md-12 ">
                    <nuxt-link :to="getRouteCosts(model, 'vergelijk/autokosten')"
                               @click.native="reportEvent('tco', model)" class="specs secondary" rel="noopener">
                      Autokosten
                    </nuxt-link>
                    <nuxt-link :to="getRoute(model, 'elektrische-auto')" @click.native="reportEvent('specs', model)"
                               class="specs" itemprop="url" rel="noopener">Specificaties
                    </nuxt-link>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <transition name="slide-up">
          <div v-show="selectedCars && selectedCars.length > 0" class="compare-card">
            <comparing-card :open-collapse="true" :selected-cars="selectedCars" />
          </div>
        </transition>

      </div>

    </div>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters'
import SegmentModule from '~/modules/segment'
import CurrencyModule from '~/modules/currency'
import Shape from '@/components/design/shape'
import socialImage from "@/assets/banner.png"
import BannerImg from "@/assets/banner.png"
import vSelect from "vue-select";
import ComparingCard from '@/components/comparingCard.vue'

export default {
  name: 'elektrische-auto',
  components: {
    Shape,
    ComparingCard,
    vSelect

  },
  mixins: [Vue2Filters.mixin, SegmentModule, CurrencyModule, socialImage],

  head() {
    var img = "@/assets/banner.png"
    var title = "Actuele en te verwachten elektrische auto's | WhatTheRange"
    var desc = `Alle actuele en te verwachten elektrische auto's. Bekijk uitgebreid de actieradius, het verbruik, elektrische specificaties, auto opties, verwachte restwaarde en onderhoud.`
    var url = 'https://www.whattherange.com/elektrische-auto'
    var keywords = "Elektrische auto, Auto, WLTP, NEDC, Actieradius, Verbruik, Autogegevens, Vergelijk, WhatTheRange, Verwachte actieradius, Verwachte verbruik, Specificaties"
    if (this.$route.params.filter !== undefined) {
      for (let i in this.$store.state.filterOptions)
        if (this.$store.state.filterOptions[i].friendly == this.$route.params.filter) {
          title = `Elektrische auto's gesorteerd op ${this.$store.state.filterOptions[i].name.toLowerCase()} | WhatTheRange`
          desc = `Actuele en te verwachten elektrische auto's gesorteerd op ${this.$store.state.filterOptions[i].name.toLowerCase()}. Bekijk uitgebreid de actieradius, het verbruik, elektrische specificaties, auto opties, verwachte restwaarde en onderhoud.`
          url = 'https://www.whattherange.com/elektrische-auto/' + this.$store.state.filterOptions[i].friendly
          keywords = `${this.$store.state.filterOptions[i].name}, Elektrische auto, Auto, WLTP, NEDC, Actieradius, Verbruik, Autogegevens, Vergelijk, WhatTheRange, Verwachte actieradius, Verwachte verbruik, Specificaties`
        }
    }

    return {
      title: title,
      meta: [
        {hid: 'og:image', property: 'og:image', content: 'https://www.whattherange.com' + socialImage},
        {hid: 'image', name: 'image', content: 'https://www.whattherange.com' + socialImage},
        {hid: 'title', name: 'title', content: title},
        {hid: 'og:title', property: 'og:title', content: title},
        {hid: 'description', name: 'description', content: desc},
        {hid: 'og:description', property: 'og:description', content: desc},
        {hid: 'keywords', name: 'keywords', content: keywords},
        {hid: 'og:keywords', property: 'og:keywords', content: keywords},
        {hid: 'url', name: 'url', content: url, rel: "noopener"},
        {hid: 'og:url', property: 'og:url', content: url}
      ]
    }
  },

  data() {
    return {
      selectedFilter: {
        webserviceField: null,
        webserviceSort: null
      },
      filterSearch: '',
      searchQuery: undefined,
      hideScrollToDataBtn: true,
      noImage: BannerImg,
      selectedMerk: undefined,
      selectedModel: undefined,
      selectedUitvoering: undefined,
      merken: [],
      modellen: [],
      uitvoeringen: [],
      uitvoeringMotoren: [],
      selectedCars: [],


    }
  },
  watchQuery: ['search'],

  computed: {

    cacheControlHeaderDuration() {
      return process.env.VUE_APP_CACHE_CONTROL_DURATION || 3600;
    },

    models() {
      return this.$store.state.models
    },

    filterList: function () {
      if (this.models) {
        var searchRegex = new RegExp(this.filterSearch, 'i');
        let models = this.models
        return models.filter(function (model) {
          return (searchRegex.test(model.cMerknaam) || searchRegex.test(model.cModelnaam) || searchRegex.test(model)) &&
            (!this.selectedMerk || model.cMerknaam === this.selectedMerk) &&
            (!this.selectedModel || model.cModelnaam === this.selectedModel);
        }.bind(this));
      }
    }

  },

  async asyncData({app, store, params}) {
    await store.dispatch('getModels')
  },

  methods: {



    handleCheckboxChange() {
      if (this.selectedCars.length > 3) {
        this.selectedCars = this.selectedCars.slice(0, 3); // Limit to three IDs
        sessionStorage.setItem('selectedCars', JSON.stringify(this.selectedCars));
      } else {
        sessionStorage.setItem('selectedCars', JSON.stringify(this.selectedCars));
      }
    },

    // getServerModels(){
    //   this.models = this.$store.dispatch('getModels')
    // },
    updateFilterUrl(filterObj) {
      this.$gtag('event', `changed-sorting-mode`, {
        event_category: 'Model List',
        event_label: this.$route.params.filter
      });

      this.selectedFilter = filterObj;
      let filter = filterObj.friendly;

      let url = '';
      if (this.selectedMerk) {
        url += this.selectedMerk;
        // if (this.selectedModel) {
        //   url += '-' + this.selectedModel;
        // }

      }

      if (url) {
        url += '-';
      }

      url += filter;

      url = url.replace(/\s/g, '_');

      if (url === 'undefined') {
        this.$router.push({name: 'elektrische-auto-filter', params: {filter}});

      } else if (filter === undefined) {
        filter = this.selectedMerk
        filter = filter.replace(/\s/g, '_');

        // if (this.selectedModel) {
        //   filter = this.selectedMerk + '-' + this.selectedModel
        //
        // }

        this.$router.push({name: 'elektrische-auto-filter', params: {filter}});
      } else {
        this.$router.push({name: 'elektrische-auto-filter', params: {filter: url}});
      }


    },


    getParamFilter() {
      for (let i in this.$store.state.filterOptions) {
        if (this.$route.params.filter.includes(this.$store.state.filterOptions[i].friendly)) {
          this.selectedFilter = this.$store.state.filterOptions[i]
        }

      }
    },

    /**
     * @method ROUTER
     * @return PAGE
     */
    getRoute(model, prefix) {
      const seoModel = model.cMerknaam + '-' + model.cModelnaam.replace(/\s+/g, '-');
      const id = model.nModelID
      return '/' + prefix + '/' + id + '/' + seoModel;
    },

    reportEvent(event, model) {
      const seoModel = model.cMerknaam + '-' + model.cModelnaam.replace(/\s+/g, '-');
      const id = model.nModelID;
      this.$gtag('event', `clicked-vehicle-${event}`, {
        event_category: 'Model List',
        event_label: seoModel,
        value: id,
      });
    },

    getRouteCosts(model, prefix) {
      const motor = model.motor[0].typeinformation[0]
      const typeId = model.motor[0].nTypeID
      const seoModel = model.cMerknaam + '-' + model.cModelnaam.replace(/\s+/g, '-') + '-' + motor.cTypenaam_1.replace(/\s+/g, '-') + '-' + motor.cTypenaam_2.replace(/\s+/g, '-');
      const id = model.nModelID
      return '/' + prefix + '/' + typeId + '/' + seoModel
    },

    getSegment(segmentCode) {
      return SegmentModule.getSegmentBySegmentCode(segmentCode)
    },
    carPrice(price) {
      return CurrencyModule.carPrice(price)
    },

    getTypeRoute(motor) {
      const type1 = motor.typeinformation[0].cTypenaam_1.replace(/\s+/g, '-')
      const type2 = motor.typeinformation[0].cTypenaam_2.replace(/\s+/g, '-')
      return '/' + type1 + '-' + type2;
    },

    onImageError() {
      this.error = null;
      console.log("firing onImageError");
      this.src = this.noImage
    }


  },

  watch: {


    searchQuery(newVal) {
      if ((newVal !== '' && newVal !== undefined) && this.$route.query.search !== newVal) {
        this.filterSearch = newVal;
      } else if (newVal == this.$route.query.search || (newVal === undefined && this.$route.query.search !== undefined)) {
        this.filterSearch = this.$route.query.search;
      } else {
        this.filterSearch = '';
      }
    },
    selectedMerk: function (newVal) {
      this.$store.commit('setSelectedMerk', this.selectedMerk);
      // this.selectedModel = undefined;
      let modellen = this.models.map((modellen) => {
        if (modellen.cMerknaam === newVal) {
          return modellen.cModelnaam;
        }
      });

      this.modellen = modellen.filter(function (el) {
        return el != null;
      });

      if (!this.modellen.includes(this.selectedModel))
        this.selectedModel = null
      // console.log('models: ', this.modellen)

      this.updateFilterUrl(this.selectedFilter);
    },

    selectedModel: function (newVal) {
      this.$store.commit('setSelectedModel', this.selectedModel);
      let uitvoeringMotoren = this.models.map((modellen) => {
        if (modellen.cModelnaam === newVal) {
          return modellen.motor;
        }
      });

      this.updateFilterUrl(this.selectedFilter);

    }
  },
  beforeMount() {
    this.searchQuery = this.$route.query.search ? this.$route.query.search : '';
  },
  mounted() {
    if (typeof sessionStorage !== 'undefined' && sessionStorage.getItem('selectedCars')) {
        this.selectedCars = JSON.parse(sessionStorage.getItem('selectedCars'));
    }


    // console.log(' ik kom hier ' , this.selectMerk);
    this.filterSearch = this.$route.query.search ? this.$route.query.search : '';
    this.$gtag('event', 'screen_view', {
      'app_name': 'whattherange',
      'screen_name': 'Model List',
    });
    this.$gtag('event', `mounted`, {
      event_category: 'Model List',
      event_label: this.$route.params.filter
    });

    // set Cache-Control header
    if (this.$http) {
      // set Cache-Control header
      this.$http.headers.common['Cache-Control'] = 'max-age=3600, public';
    }

    if (!this.selectedModel) {
      this.selectedModel = this.selectModel;
    }

    this.merken = this.models?.map(item => item.cMerknaam)
      .filter((value, index, self) => self.indexOf(value) === index)
    //
    if (this.$route.params.filter) {
      const filterParts = this.$route.params.filter.split('-');
      const firstPart = filterParts[0].replace('_', ' ');

      for (let i in this.merken) {
        if (firstPart.includes(this.merken[i])) {
          this.selectedMerk = this.merken[i];

          // Replace und
          break;
        }
      }
    }
  },

  created() {
    // this.$store.dispatch('getModels')
    if (this.$route.params.filter !== undefined)
      this.getParamFilter();
    this.$store.state.models = this.models;
  },
}
</script>
<style scoped >
.input-search {
  background: rgba(46, 204, 113, 0.08) !important;
  border: 1px solid #28a745;
}



.custom-checkbox input:focus-visible + .checkmark {
  box-shadow: 0 0 0 3px rgba(85, 85, 85, 0.5);
}




</style>



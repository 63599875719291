const SegmentModule = {
  /**
  * @method List
  * @return list of segment with segmentcode
  */
  getSegmentCodes(){
    return {
      A: 'submini\'s',
      B: 'kleine auto\'s',
      C: 'kleine middenklasse',
      D: 'middenklasse',
      E: 'hogere middenklasse',
      F: 'grote auto\'s',
      GA: 'sportieve submini\'s',
      GB: 'sportieve kleine auto\'s',
      GC: 'sportieve kleine middenklasse',
      GD: 'sportieve middenklasse',
      GE: 'sportieve hogere middenklasse',
      GF: 'sportieve grote auto\'s',
      GG: 'sportieve modellen ',
      H: 'supercars',
      I: 'grote en luxueuze auto',
      JA: 'MPV submini\'s',
      JB: 'MPV kleine auto\'s',
      JC: 'MPV kleine middenklasse',
      JD: 'MPV middenklasse',
      JE: 'MPV hogere middenklasse',
      JF: 'MPV grote auto\'s',
      LA: 'SUV submini\'s',
      LB: 'SUV kleine auto\'s',
      LC: 'SUV kleine middenklasse',
      LD: 'SUV middenklasse',
      LE: 'SUV hogere middenklasse',
      LF: 'SUV grote auto\'s',
      LI: 'SUV grote en luxueuze auto',
      N: 'bestelauto’s'
    }
  },

  /**
  * @method GET
  * @return segment by segmentcode from list
  */
  getSegmentBySegmentCode(segmentCode) {
    return this.getSegmentCodes()[segmentCode];
  },

}

export default SegmentModule
